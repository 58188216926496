import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/Layout/Layout';
import { StaticQuery, graphql } from 'gatsby';
import Title from '../UI/Title';
import SubTitle from '../UI/SubTitle';
import Map from '../components/Maps/Map';
import { Email } from '../UI/Email';
import { Phone } from '../UI/Phone';
import { getSafe } from '../utils/getSafe';
import { WhatsApp } from '../UI/WhatsApp';
import './scss/contact.scss';

const Contact = props => {
    const places = (info, sectionTitle) => (
        <section className="gray">
            <div className="child cfp-container inner">
                <Title text={sectionTitle} delay={200} />
                <Map info={info} />
            </div>
        </section>
    );
    const form = (title, description, contact) => {
        const correo = getSafe(() => contact.correo);
        const telefono = getSafe(() => contact.telefono);
        return (
            <section className="white">
                <div className="child cfp-container inner">
                    {title && <Title text={title} delay={200} />}
                    {description && <SubTitle delay={200}>{description.replace(/(?:\r\n|\r|\n)/g, '<br>')}</SubTitle>}
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="contact-info is-flex" delay={100}>
                        {correo && <Email correo={correo}/>}
                        {telefono && <Phone telefono={telefono} />}
                        {telefono && <WhatsApp telefono={telefono}/>}
                    </ScrollAnimation>
                </div>
            </section>
        );
    };
    return (
        <StaticQuery
            query={graphql`
                query {
                    contentfulContactoPagina {
                        edificio
                        lugaresAtencionTitulo
                        horarioAtencion {
                            childMarkdownRemark {
                                html
                            }
                        }
                        coordenadasLatitud
                        coordenadasLongitud
                        comunicateTitulo
                        comunicateDescripcion {
                            childMarkdownRemark {
                                html
                            }
                        }
                        direccion {
                            childMarkdownRemark {
                                html
                            }
                        }
                    }
                    contentfulFooterSeccion {
                        telefono
                        correo
                    }
                }
            `}
            render={data => {
                const sectionTitle = getSafe(() => data.contentfulContactoPagina.lugaresAtencionTitulo, '');
                const title = getSafe(() => data.contentfulContactoPagina.edificio, '');
                const address = getSafe(() => data.contentfulContactoPagina.direccion.childMarkdownRemark.html, '');
                const map = {
                    long: getSafe(() => data.contentfulContactoPagina.coordenadasLongitud, ''),
                    lat: getSafe(() => data.contentfulContactoPagina.coordenadasLatitud, ''),
                };
                const schedule = getSafe(
                    () => data.contentfulContactoPagina.horarioAtencion.childMarkdownRemark.html,
                    ''
                );
                const info = {
                    title,
                    address,
                    map,
                    schedule,
                };
                const comTitle = getSafe(() => data.contentfulContactoPagina.comunicateTitulo, '');
                const comDescription = getSafe(
                    () => data.contentfulContactoPagina.comunicateDescripcion.childMarkdownRemark.html,
                    ''
                );
                const contact = data.contentfulFooterSeccion;
                return (
                    <Layout title="Contacto" {...props}>
                        <div className="contact">
                            {places(info, sectionTitle)}
                            {form(comTitle, comDescription, contact)}
                        </div>
                    </Layout>
                );
            }}
        />
    );
};

export default Contact;
