import React from 'react';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';;

export const WhatsApp = ({telefono}) => {
    return (
        <div className="contact-info-row text">
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://api.whatsapp.com/send?phone=${telefono.replace(/[- )(]/g, '')}`}
            >
                <span className="contact-icon">
                    <WhatsAppIcon />{' '}
                </span>
                Chat: {telefono}
            </a>
        </div>
    );
}
