import React from "react";
import "./LoadingSpinner.scss";

const LoadingSpinner = ({
    backgroundColor = '#acd659',
    bulletColor = '#fff',
    isLoading = true,
    height = '100vh',
    width = '100vw',
}) => {
    return (
        <>
            {isLoading ? (
                <div className={`spinner ${isLoading}`} style={{ backgroundColor, height, width }}>
                    <div
                        className="bounce1"
                        style={{
                            backgroundColor: bulletColor,
                        }}
                    />
                    <div
                        className="bounce2"
                        style={{
                            backgroundColor: bulletColor,
                        }}
                    />
                    <div
                        className="bounce3"
                        style={{
                            backgroundColor: bulletColor,
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};

export default LoadingSpinner;
