import React, { useState } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import './Maps.scss';

const Map = ({ info }) => {
    const [loading, setLoading] = useState(true);
    const { map } = info;
    return (
        <div className="contact-grid">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="contact-card" delay={200}>
                <div className="map">
                    {loading && (
                        <LoadingSpinner backgroundColor="white" width="100%" height="100%" bulletColor="black" />
                    )}
                    <iframe
                        width="600"
                        height="450"
                        frameBorder="0"
                        style={{ border: 0 }}
                        src={`https://www.google.com/maps/embed/v1/place?q=${map.long}%2C%20${map.lat}&key=AIzaSyCScKavQYrwnOMO-MzG9_3uXfx0T1vYjw4`}
                        allowFullScreen={true}
                        onLoad={() => setLoading(false)}
                        title="Mapa"
                    ></iframe>
                </div>
                <div className="info">
                    {info.title && (
                        <div className="cfp-content-title" dangerouslySetInnerHTML={{ __html: info.title }} />
                    )}
                    {info.address && (
                        <div
                            className="text address"
                            dangerouslySetInnerHTML={{ __html: info.address.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                        />
                    )}
                    {info.schedule && (
                        <div
                            className="text schedule"
                            dangerouslySetInnerHTML={{ __html: info.schedule.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
                        />
                    )}
                </div>
            </ScrollAnimation>
        </div>
    );
};

export default Map;
